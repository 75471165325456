import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { SectionTitle, ImageSharpFluid } from 'helpers/definitions';

import * as Styled from './styles';
import FormatHtml from 'components/utils/FormatHtml';

interface Post {
	node: {
		id: string;
		fields: {
			slug: string;
		};
		frontmatter: {
			title: string;
			description: string;
			date: string;
			tags: string[];
			passwordProtected: boolean;
			cover?: {
				childImageSharp: {
					fluid: ImageSharpFluid;
				};
			};
		};
	};
}

const Posts: React.FC = () => {
	const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
		query {
			markdownRemark(frontmatter: { category: { eq: "blog section" } }) {
				frontmatter {
					title
				}
				html
			}
			allMarkdownRemark(
				filter: { frontmatter: { category: { eq: "blog" }, published: { eq: true } } }
				sort: { fields: frontmatter___date, order: DESC }
			) {
				edges {
					node {
						id
						html
						fields {
							slug
						}
						frontmatter {
							title
							description
							date(formatString: "DD.MM.YYYY")
							tags
							passwordProtected
							cover {
								childImageSharp {
									fluid(maxWidth: 768, maxHeight: 200, cropFocus: CENTER) {
										...GatsbyImageSharpFluid_withWebp
										...GatsbyImageSharpFluidLimitPresentationSize
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const sectionTitle: SectionTitle = markdownRemark.frontmatter;
	const posts: Post[] = allMarkdownRemark.edges;

	return (
		<Container section>
			<TitleSection title={sectionTitle.title} center />
			<div>{markdownRemark.html && <FormatHtml content={markdownRemark.html} />}</div>
			<Styled.Posts>
				{posts.map((item) => {
					const {
						id,
						fields: { slug },
						frontmatter: { title, cover, description, date, tags, passwordProtected }
					} = item.node;

					if (passwordProtected) {
						return (
							<Styled.Post key={id}>
								<Styled.Card>
									{cover && (
										<Styled.Image>
											<Img fluid={cover.childImageSharp.fluid} alt={title} />
										</Styled.Image>
									)}
									<Styled.Content>
										<Styled.Date>{date}</Styled.Date>
										<Styled.Title>🔐 {title}</Styled.Title>
										<Styled.Description>
											Es gibt keinen Textauszug, da dies ein geschützter Beitrag ist.
										</Styled.Description>
									</Styled.Content>
									<Styled.Tags>{tags && tags.map((item) => <Styled.Tag key={item}>{item}</Styled.Tag>)}</Styled.Tags>
								</Styled.Card>
							</Styled.Post>
						);
					}

					return (
						<Styled.Post key={id}>
							<Link to={slug}>
								<Styled.Card>
									{cover && (
										<Styled.Image>
											<Img fluid={cover.childImageSharp.fluid} alt={title} />
										</Styled.Image>
									)}
									<Styled.Content>
										<Styled.Date>{date}</Styled.Date>
										<Styled.Title>{title}</Styled.Title>
										<Styled.Description>{description}</Styled.Description>
									</Styled.Content>
									<Styled.Tags>{tags && tags.map((item) => <Styled.Tag key={item}>{item}</Styled.Tag>)}</Styled.Tags>
								</Styled.Card>
							</Link>
						</Styled.Post>
					);
				})}
			</Styled.Posts>
		</Container>
	);
};

export default Posts;
